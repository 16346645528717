"use strict";
import React from "react";
import { Text } from "@citifyd/style";
import Table from "../../../shared/react/components/Table";
import { useService, useTranslator } from "../../../shared/react/hooks";
import { formatValue } from "./functions/utils";
import Total from "./Total.jsx";
import { scrollTo } from "../../../shared/services/helper";
import styles from "../Payout.module.scss";

const Summary = ({ items, total, currency, tableRefs, lotName }) => {
  const t = useTranslator();

  const columns = [
    { key: "name", value: t("payout.type") },
    {
      key: "total",
      value: <Text textAlign="right">{t("payout.revenue")} </Text>,
    },
  ];

  const data = [
    ...items
      .filter((item) => !item.hidden)
      .map((item) => ({
        ...item,
        total: <Total value={item.total} currency={currency} />,

        onClick: item.hasItems
          ? () => {
              const element = tableRefs[item.refName].current;
              scrollTo(element);
            }
          : null,
      })),
    {
      name: <Text bold>{t("payout.total")}</Text>,
      total: (
        <Text textAlign="right" bold>
          {formatValue({
            value: total,
            currency,
          })}
        </Text>
      ),
    },
  ];

  return (
    <div className={styles.summary}>
      <Text variant="h3" gutterBottom>
        {lotName ? t("payout.summaryForLot", { lotName }) : t("payout.summary")}
      </Text>
      <Table columns={columns} data={data} gutterBottom responsive striped />
    </div>
  );
};

export default Summary;
